import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  useImperativeHandle,
} from "react";
import { ControlledTreeEnvironment, Tree } from "react-complex-tree";
import type { TreeRef } from "react-complex-tree";
import { generateNode, MENU_KEY, PAGE_KEY, reverseConvertData } from "./data";
import * as _ from "lodash";
import { Classes } from "@blueprintjs/core";
import {
  EditOutlined,
  EyeOutlined,
  EyeInvisibleOutlined,
  DeleteOutlined,
  CheckOutlined,
} from "@ant-design/icons";
import "react-complex-tree/lib/style-modern.css";
// import IconSelector from "./IconSelector";
import NewIconSelector from "components/IconSelect"
import { renderers } from "./renderers";
import { message } from "antd";
import styled from "styled-components";
const cx = (...classNames: Array<string | undefined | false>) =>
  classNames.filter((cn) => !!cn).join(" ");

const IconWrapper = styled.div`
  border-radius: 2px;
  // background: linear-gradient(45deg, rgb(238, 238, 238) 20%, rgb(255, 255, 255) 0px, rgb(255, 255, 255) 40%, rgb(238, 238, 238) 0px, rgb(238, 238, 238) 60%, rgb(255, 255, 255) 0px, rgb(255, 255, 255) 80%, rgb(238, 238, 238) 0px, rgb(238, 238, 238) 100%, rgb(255, 255, 255) 0px);
`;

interface MenuProps {
  poolData: any; // 自由页面数据
  treeData: any; // 树形菜单数据
  mRef: any; // 传递的ref
}

export default function App(props: MenuProps) {
  const { poolData, treeData } = props;
  const sortmenuRef = useRef<any>(null);
  const treeRef = useRef<TreeRef>(null);
  const [symbolF, setSymbolF] = useState<symbol>();

  const [focusedItem, setFocusedItem] = useState();
  const [expandedItems, setExpandedItems] = useState([]);
  const [menuList, setMenuList] = useState({});
  const [poolList, setPoolList] = useState({});
  const [hideList, setHideList] = useState<any[]>([]);
  useEffect(() => {
    setMenuList(treeData);
    setPoolList(poolData);
    setHideList([]);
  }, [treeData, poolData]);

  const refresh = () => {
    setSymbolF(Symbol("change"));
  };

  const itIsFolder = (name: string) => {
    return !!_.get(sortmenuRef.current.items[name], "isFolder");
  };

  const createFold = () => {
    const newFold = generateNode();
    sortmenuRef.current.items[newFold.index] = newFold;
    sortmenuRef.current.items[MENU_KEY].children.push(newFold.index);
    refresh();
    setMenuList(sortmenuRef.current.items);
  };

  const moveBelowPageBackToPool = (node: any, poolName: string) => {
    const folders: any[] = [];
    folders.push(node.key);
    const clearChildren = (menu: any) => {
      _.each(menu.children, (menu_child: string) => {
        if (itIsFolder(menu_child)) {
          const theFoldNode = sortmenuRef.current.items[menu_child];
          clearChildren(theFoldNode);
          folders.push(menu_child);
        } else {
          menu.children = menu.children.filter(
            (cc: string) => cc !== menu_child,
          ); // 移出
          sortmenuRef.current.items[poolName].children.push(menu_child); //移进
        }
      });
    };

    clearChildren(node);
    return folders;
  };

  // 删除菜单目录
  const onDeleteNode = (node: any) => {
    if (_.get(node, "isFolder")) {
      // 遍历下面的页面，移送到页面池中
      const emptyFolders = moveBelowPageBackToPool(node, PAGE_KEY);
      const list = sortmenuRef.current.items;
      // 移除文件夹
      emptyFolders.map((fkey) => {
        for (const mkey in list) {
          const item = list[mkey];
          if (item.children && item.children.includes(fkey)) {
            item.children = item.children.filter((c: any) => c !== fkey);
          }
        }
      });
      sortmenuRef.current.items = _.omit(
        sortmenuRef.current.items,
        emptyFolders,
      );
      refresh();
    }
  };

  //隐藏菜单
  const onHideNode = (node: any, tohide: boolean) => {
    if (!node.key) {
      return;
    }
    let _list: any = [];
    const handleHideKey = (tree: any) => {
      _list.push(tree.key);
      _.set(sortmenuRef.current.items, [tree.key, "isHidden"], tohide);
      if (tree.children && _.size(tree.children)) {
        tree.children.map((nc: any) => {
          const c_node = sortmenuRef.current.items[nc];
          _list = _list.concat(handleHideKey(c_node));
        });
      }
      return _list;
    };
    handleHideKey(node);
    let _hidel: any[] = [];
    if (!tohide) {
      // 展示
      _hidel = _.filter(hideList, (m: string) => !_list.includes(m));
    } else {
      // 隐藏
      _hidel = _.uniq(hideList.concat(_list));
    }
    setHideList(_hidel);
  };

  //重命名菜单
  const onRenameNode = (node: any, name: any) => {
    const nameL = name.length;
    if (nameL > 7) {
      message.warning("菜单名称不超过7个字符");
      return;
    }
    sortmenuRef.current.items[node.key]["title"] = name;
  };

  const onHandleDrop = (item: any, target: any) => {
    const toBox =
      target.targetType === "between-items"
        ? target.parentItem
        : target.targetItem;
    const toIndex = target.childIndex;
    const convertData = _.get(_.head(item), "data", "");
    moveToIndex(toBox, toIndex, convertData);
  };

  /**
   *
   * @param treeId 被插入的ID
   * @param targetIdx 被插入的索引值
   * @param element 插入的元素
   */
  const moveToIndex = (treeId: string, targetIdx: number, element: any) => {
    const list = sortmenuRef.current.items;
    // 移除旧队列
    for (const mkey in list) {
      const item = list[mkey];
      if (item.children && item.children.includes(element)) {
        item.children = item.children.filter((c: any) => c !== element);
        // console.log(`把${element} 从原位置移除`)
      }
    }
    // 插进新队列
    for (const mkey in list) {
      const item = list[mkey];
      if (mkey === treeId) {
        if (item.children) {
          item.children?.splice(targetIdx, 0, element); // 在目标索引位置插入元素A
          // console.log(`把${element} 插到新队列${mkey}的索引【${targetIdx}】位置`)
        } else {
          item.children = [element];
        }
      }
    }
    refresh();
  };

  const onSubmit = () => {
    const theTreeData = reverseConvertData(sortmenuRef.current.items, MENU_KEY);
    const menuData = theTreeData.find((t: any) => t.key === MENU_KEY)?.children;
    const thePoolData = reverseConvertData(sortmenuRef.current.items, PAGE_KEY);
    const outsideTree = thePoolData.filter((t: any) => t.key !== PAGE_KEY);

    return {
      menuData,
      outsideTree,
      // hideList,
    };
  };

  useImperativeHandle(props.mRef, () => ({
    handleSubmit: onSubmit,
    handleAdd: createFold,
  }));

  const handleIconSelect = (target:any, icon:any) => {
    sortmenuRef.current.items[target.key]["icon"] = icon;
    setSymbolF(Symbol("refresh"));
  };
  return (
    <div className="viewerlayout pageplug-menu-editor">
      <span style={{ visibility: "hidden" }}>{symbolF}</span>
      <ControlledTreeEnvironment<string>
        canDragAndDrop
        canDropOnFolder
        canReorderItems
        getItemTitle={(item) => item.title}
        items={{ ...menuList, ...poolList }}
        ref={sortmenuRef}
        renderTreeContainer={({ children, containerProps, info }) => (
          <div
            className={[
              "rct-tree-root",
              info.isFocused && "rct-tree-root-focus",
              info.isRenaming && "rct-tree-root-renaming",
              info.areItemsSelected && "rct-tree-root-itemsselected",
            ].join(" ")}
          >
            <div
              {...containerProps}
              style={{ minHeight: "200px", ...containerProps.style }}
            >
              {children}
            </div>
          </div>
        )}
        viewState={{
          [`${MENU_KEY}-tree`]: {
            focusedItem,
            expandedItems,
          },
        }}
        {...renderers}
        onCollapseItem={(item) =>
          setExpandedItems(expandedItems.filter((ep) => ep !== item.index))
        }
        onDrop={(item, target) => onHandleDrop(item, target)}
        onExpandItem={(item) =>
          setExpandedItems([...expandedItems, item.index])
        }
        onFocusItem={(item) => setFocusedItem(item.index)}
        onRenameItem={(item, name) => onRenameNode(item, name)}
        renderItem={(props) => {
          const isFromList: boolean = props.info.treeId === `${PAGE_KEY}-tree`;
          const { context } = props;
          const hide: boolean = !!props.item?.isHidden && !isFromList;
          const isDraggingOver = context.isDraggingOver;
          return (
            <li
              className={cx(
                Classes.TREE_NODE,
                (props.context.isSelected || props.context.isDraggingOver) &&
                Classes.TREE_NODE_SELECTED,
              )}
              {...(props.context.itemContainerWithChildrenProps as any)}
            >
              {/* 展开收缩符号 */}
              <div className={"arrowitem"}>
                {_.get(props, "item.isFolder") &&
                  _.size(_.get(props, "item.children")) ? (
                  props.arrow
                ) : (
                  <span className={Classes.TREE_NODE_CARET_NONE} />
                )}
              </div>
              {/* 展开收缩符号 */}

              <div
                className={cx(
                  Classes.TREE_NODE_CONTENT,
                  "menu_item",
                  `${Classes.TREE_NODE_CONTENT}-${props.depth}`,
                )}
                {...(props.context.itemContainerWithoutChildrenProps as any)}
                {...(props.context.interactiveElementProps as any)}
              >
                <div
                  className={cx(
                    "menuitem",
                    _.get(props, "item.isFolder") && "menufold",
                    hide && "menuhide",
                    isDraggingOver && "menuDraggingOver",
                  )}
                >
                  {/* 标题 */}
                  <IconWrapper>
                   <NewIconSelector
                      hideNoneIcon 
                      mode="simple"
                      onSelect={(tIcon: any) =>
                        handleIconSelect(props.item, tIcon)
                      }
                      rowKey={`${_.get(props, "item.key")}`}
                      value={_.get(props, "item.icon", {})} 
                    /> 
                  </IconWrapper>
                  <span style={{ margin: "0px 10px" }}>{props.title}</span>
                  {/* 编辑按钮 */}
                  {!isFromList &&
                    _.get(props, "item.isFolder") &&
                    !context.isRenaming ? (
                    <span className={"menuicon"}>
                      <EditOutlined
                        onClick={(e) => {
                          e.stopPropagation();
                          context.startRenamingItem();
                        }}
                      />
                    </span>
                  ) : null}
                  {!isFromList && (
                    <>
                      <span className={cx("extra", "menuicon", hide && "hide")}>
                        {/* 删除 */}
                        {_.get(props, "item.isFolder") ? (
                          <DeleteOutlined
                            onClick={(e) => {
                              e.stopPropagation();
                              onDeleteNode(props.item);
                            }}
                          />
                        ) : null}
                        {/* 可见 */}
                        <EyeOutlined
                          onClick={(e) => {
                            onHideNode(props.item, true);
                            e.stopPropagation();
                          }}
                        />
                      </span>
                      <span
                        className={cx("extra", "menuiconfix", !hide && "hide")}
                      >
                        <EyeInvisibleOutlined
                          onClick={(e) => {
                            e.stopPropagation();
                            onHideNode(props.item, false);
                          }}
                        />
                      </span>
                    </>
                  )}
                </div>
              </div>
              <div
                className={cx(Classes.COLLAPSE)}
                style={
                  props.context.isExpanded
                    ? {
                      height: "auto",
                      overflowY: "visible",
                      transition: "none 0s ease 0s",
                    }
                    : {}
                }
              >
                {props.children}
              </div>
            </li>
          );
        }}
        renderItemTitle={({ context, info, title }) => {
          if (info.treeId === `${PAGE_KEY}-tree`) {
            return <span className={Classes.TREE_NODE_LABEL}>{title}</span>;
          }
          return <span className={Classes.TREE_NODE_LABEL}>{title}</span>;
        }}
        renderRenameInput={(props) => {
          return (
            <form {...props.formProps} style={{ display: "contents" }}>
              <span className={Classes.TREE_NODE_LABEL}>
                <input
                  {...props.inputProps}
                  className="rct-tree-item-renaming-input"
                  ref={props.inputRef}
                />
              </span>
              <CheckOutlined
                {...(props.submitButtonProps as any)}
                {...props.submitButtonProps}
                ref={props.submitButtonRef}
                type="submit"
              />
            </form>
          );
        }}

      // onMissingItems={items => alert(`We should now load the items ${items.join(", ")}...`)}
      >
        <div className="page_pool bg-gray-50 rounded border-dashed border border-gray-300 hover:border-primary-200 relative">
          <span className="absolute inset-0 flex items-center justify-center text-gray-300 font-xl tracking-wide">
            页面池
          </span>
          <Tree rootItem={PAGE_KEY} treeId={`${PAGE_KEY}-tree`} treeLabel="" />
        </div>
        <div
          className={
            "menu_pool rounded border-solid border border-gray-200 relative min-h-[100px]"
          }
        >
         
          <Tree
            ref={treeRef}
            rootItem={MENU_KEY}
            treeId={`${MENU_KEY}-tree`}
            treeLabel=""
          />
        </div>
      </ControlledTreeEnvironment>
    </div>
  );
}
