import React from "react";

const ModuleSvg = ({ width = "24px", height = "24px", style = {} }) => (
  <svg
    width="19px"
    height="20px"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    style={{ flexShrink: 0, ...style }}
    // xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <rect x="0" y="0" width="20" height="20"></rect>
      <g transform="translate(2.000000, 0.000000)">
        <g>
          <path
            d="M2,0 L10,0 L10,0 L16,6.00453385 L16,18 C16,19.1045695 15.1045695,20 14,20 L2,20 C0.8954305,20 1.3527075e-16,19.1045695 0,18 L0,2 C-1.3527075e-16,0.8954305 0.8954305,-1.91384796e-17 2,0 Z"
            fill="#FA9C3F"
          ></path>
          <path
            d="M10,0 L16,6 L11,6 C10.4477153,6 10,5.55228475 10,5 L10,0 L10,0 Z"
            fill="#EEEEEE"
          ></path>
        </g>
        <g transform="translate(4.000000, 10.000000)" fill="#FFFFFF">
          <path d="M0.5,3.0625 L6.5,3.0625 C6.77614237,3.0625 7,3.28635763 7,3.5625 L7,6.5 C7,6.77614237 6.77614237,7 6.5,7 L0.5,7 C0.223857625,7 -2.43738069e-16,6.77614237 0,6.5 L0,3.5625 C-3.38176876e-17,3.28635763 0.223857625,3.0625 0.5,3.0625 Z"></path>
          <path d="M0.5,5.68434189e-14 L3.38888889,5.68434189e-14 C3.66503126,5.65474286e-14 3.88888889,0.223857625 3.88888889,0.5 L3.88888889,1.6875 C3.88888889,1.96364237 3.66503126,2.1875 3.38888889,2.1875 L0.5,2.1875 C0.223857625,2.1875 -7.72046149e-17,1.96364237 0,1.6875 L0,0.5 C-3.38176876e-17,0.223857625 0.223857625,5.67831231e-14 0.5,5.68434189e-14 Z"></path>
          <path d="M5.16666667,0 L6.5,0 C6.77614237,-5.07265313e-17 7,0.223857625 7,0.5 L7,1.6875 C7,1.96364237 6.77614237,2.1875 6.5,2.1875 L5.16666667,2.1875 C4.89052429,2.1875 4.66666667,1.96364237 4.66666667,1.6875 L4.66666667,0.5 C4.66666667,0.223857625 4.89052429,-6.02957711e-17 5.16666667,0 Z"></path>
        </g>
      </g>
    </g>
  </svg>
);

export default ModuleSvg;
