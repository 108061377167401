import React, { Component, useCallback, useContext, useEffect } from "react";
import styled, { createGlobalStyle } from "styled-components";
import { Alignment, Button, Classes, MenuItem } from "@blueprintjs/core";
import type { IconName } from "@blueprintjs/icons";
import { IconNames } from "@blueprintjs/icons";
import type { ItemListRenderer, ItemRenderer } from "@blueprintjs/select";
import { Select } from "@blueprintjs/select";
import type { GridListProps, VirtuosoGridHandle } from "react-virtuoso";
import { VirtuosoGrid } from "react-virtuoso";
import { replayHighlightClass } from "globalStyles/portals";
import _ from "lodash";
import { generateReactKey } from "utils/generators";
import { emitInteractionAnalyticsEvent } from "utils/AppsmithUtils";
import { Tooltip } from "design-system";
import { ANTD_ICON_NAMES } from "components/common/icon";
import AntdIcon from "components/common/AntdIcon";
import { Input, Tabs, Select as AntdSelect, ConfigProvider } from "antd";
import { useRef, useState } from "react";
import { SearchOutlined, CaretDownOutlined } from "@ant-design/icons";
import * as Icons from "@ant-design/icons";
import TabPane from "antd/es/tabs/TabPane";
import { Icon } from "@design-system/widgets";
import {
  IconSelectContainerStyles,
  StyledButton,
  StyledMenu,
  StyledMenuItem,
  StyleTopTabItem,
} from "./styled";
import type {
  IconSelectControlProps,
  IconSelectControlState,
  IconObject,
  IconType,
} from "./styled";
import { NONE, ICON_NAMES, icons, TypedSelect } from "./styled";
import { CancelIcon } from "../propertyControls/AntdIconSelectControl";
export interface IconSelectorProps {
  value?: any;
  defaultIconName?: IconName;
  hideNoneIcon?: boolean;
  mode?: "simple" | "normal";
  onSelect?: (icon: any) => void;
  rowKey?: string;
}

export interface IconSelectorState {
  currentPlate: any;
  currentTopTab: any;
  activeIcon: any;
  isOpen: boolean;
  searchValue: string;
}

export class AntdIconSelector extends React.PureComponent<
  IconSelectorProps,
  IconSelectorState
> {
  iconSelectTargetRef: React.RefObject<HTMLButtonElement>;
  virtuosoRef: React.RefObject<VirtuosoGridHandle>;
  initialItemIndex: number;
  filteredItems: any;
  searchInput: React.RefObject<HTMLInputElement>;
  filterInput: React.RefObject<HTMLInputElement>;

  id: string = this.props.rowKey || generateReactKey();
  currentPlate: string;
  currentTopTab: string;

  constructor(props: IconSelectorProps) {
    super(props);
    this.iconSelectTargetRef = React.createRef();
    this.virtuosoRef = React.createRef();
    this.searchInput = React.createRef();
    this.filterInput = React.createRef();

    this.initialItemIndex = 0;
    this.filteredItems = [];
    this.currentPlate = "";
    this.currentTopTab = "";
    /**
     * Multiple instances of the IconSelectControl class may be created,
     * and each instance modifies the ICON_NAMES array and the icons set.
     * Without the below logic, the NONE icon may be added or removed
     * multiple times, leading to unexpected behaviour.
     */
    this.state = {
      searchValue: "",
      currentPlate: !_.isEmpty(props.value)
        ? _.isString(_.get(props, "value", ""))
          ? JSON.parse(props.value)?.type
          : props.value.type
        : "antd",
      currentTopTab: !_.isEmpty(props.value)
        ? _.isString(props.value)
          ? JSON.parse(props.value)?.category
          : props.value.category
        : "direction",
      activeIcon: !_.isEmpty(props.value)
        ? _.isString(props.value)
          ? JSON.parse(props.value)
          : props.value
        : {
            iconName: "NONE",
            type: "antd",
            category: "direction",
          },
      isOpen: false,
    };
  }

  // debouncedSetState is used to fix the following bug:
  // https://github.com/appsmithorg/appsmith/pull/10460#issuecomment-1022895174
  debouncedSetState = _.debounce(
    (obj: any, callback?: () => void) => {
      if (!obj.isOpen) {
        this.setState({ searchValue: "" });
      }
      this.setState((prevState: IconSelectControlState) => {
        return {
          ...prevState,
          ...obj,
        };
      }, callback);
    },
    300,
    {
      leading: true,
      trailing: false,
    },
  );

  // shouldComponentUpdate(nextProps: any, nextState: any) {
  //   const pdiff = _.difference(
  //     _.pick(nextProps, ["mode","rowKey","defaultIconName","hideNodeIcon"]),
  //     _.pick(this.props, ["mode","rowKey","defaultIconName","hideNodeIcon"])
  //   )
  //   const sdiff = _.difference(
  //     _.pick(nextState, ["currentPlate","currentTopTab","isOpen", "activeIcon"]),
  //     _.pick(this.state, ["currentPlate","currentTopTab","isOpen", "activeIcon"])
  //   )
  //   console.log(sdiff, "sdiff")
  //   console.log(this.props.value, this.state.activeIcon)
  //   return !!_.size(pdiff) || !!_.size(sdiff) || (this.state.isOpen !== nextState.isOpen);
  // }

  componentDidMount() {
    // keydown event is attached to body so that it will not interfere with the keydown handler in GlobalHotKeys
    document.body.addEventListener("keydown", this.handleKeydown);
  }

  componentWillUnmount() {
    document.body.removeEventListener("keydown", this.handleKeydown);
  }

  handleQueryChange = _.debounce(() => {
    if (this.filteredItems.length === 2)
      this.setState({ activeIcon: this.filteredItems[1] });
  }, 50);

  render() {
    const { defaultIconName, mode } = this.props;
    const { activeIcon } = this.state;
    const containerWidth =
      this.iconSelectTargetRef.current?.getBoundingClientRect?.()?.width || 0;

    const appsmithIcon = ICON_NAMES.map((item) => {
      return {
        iconName: item,
        type: "appsmith",
        category: "appsmith",
      };
    });
    const allIcons = [{ ...ANTD_ICON_NAMES, appsmith: appsmithIcon }];
    return (
      <div className={`${this.id}_icon`}>
        <IconSelectContainerStyles
          id={`${this.id}`}
          targetWidth={containerWidth}
        />
        <TypedSelect
          activeItem={activeIcon || defaultIconName || NONE}
          filterable={false}
          className="icon-select-container"
          // inputProps={{
          //   inputRef: inputTestRef,
          // }}
          itemListRenderer={this.renderMenu}
          itemPredicate={this.filterIconName}
          itemRenderer={this.renderIconItem}
          items={allIcons}
          onItemSelect={this.handleItemSelect}
          onQueryChange={this.handleQueryChange}
          popoverProps={{
            enforceFocus: false,
            minimal: true,
            isOpen: this.state.isOpen,
            popoverClassName: `icon-select-popover icon-select-popover-${this.id}`,
            onInteraction: (state, e) => {
              e?.stopPropagation();
              // 阻止tab下拉切换被关闭
              const arr = [
                "方向性",
                "提示建议类",
                "编辑类",
                "数据类图标",
                "品牌和标识",
                "网站通用",
              ];
              const target = e?.target as HTMLElement;
              if (target) {
                const isOpen = arr.some((item) =>
                  target.innerHTML.includes(item),
                );
                if (isOpen) {
                  this.debouncedSetState({ isOpen });
                }
              }
              if (this.state.isOpen !== state)
                this.debouncedSetState({ isOpen: state });
            },
          }}
        >
          <StyledButton
            alignText={Alignment.LEFT}
            className={`${Classes.TEXT_OVERFLOW_ELLIPSIS + " " + replayHighlightClass}`}
            elementRef={this.iconSelectTargetRef}
            fill
            icon={
              activeIcon.type === "antd" ? (
                <AntdIcon
                  type={activeIcon?.iconName ?? ""}
                  style={{ fontSize: "20px", marginRight: "3px" }}
                />
              ) : (
                activeIcon.iconName || defaultIconName
              )
            }
            onClick={this.handleButtonClick}
            rightIcon="caret-down"
            tabIndex={0}
            text={
              mode === "simple"
                ? undefined
                : `${activeIcon?.iconName || defaultIconName || NONE}`
            }
          />
        </TypedSelect>
      </div>
    );
  }

  setActiveIcon(iconIndex: number) {
    this.setState(
      {
        activeIcon: this.filteredItems[iconIndex],
      },
      () => {
        if (this.virtuosoRef.current) {
          this.virtuosoRef.current.scrollToIndex(iconIndex);
        }
      },
    );
  }

  handleKeydown = (e: KeyboardEvent) => {
    if (this.state.isOpen) {
      switch (e.key) {
        case "Tab":
          e.preventDefault();
          this.setState({
            isOpen: false,
            activeIcon: this.props.value ?? NONE,
          });
          break;
        case "ArrowDown":
        case "Down": {
          emitInteractionAnalyticsEvent(this.iconSelectTargetRef.current, {
            key: e.key,
          });
          if (document.activeElement === this.searchInput.current) {
            (document.activeElement as HTMLElement).blur();
            if (this.initialItemIndex < 0) this.initialItemIndex = -4;
            else break;
          }
          const nextIndex = this.initialItemIndex + 4;
          if (nextIndex < this.filteredItems.length)
            this.setActiveIcon(nextIndex);
          e.preventDefault();
          break;
        }
        case "ArrowUp":
        case "Up": {
          if (document.activeElement === this.searchInput.current) {
            break;
          } else if (
            (e.shiftKey ||
              (this.initialItemIndex >= 0 && this.initialItemIndex < 4)) &&
            this.searchInput.current
          ) {
            emitInteractionAnalyticsEvent(this.iconSelectTargetRef.current, {
              key: e.key,
            });
            this.searchInput.current.focus();
            break;
          }
          emitInteractionAnalyticsEvent(this.iconSelectTargetRef.current, {
            key: e.key,
          });
          const nextIndex = this.initialItemIndex - 4;
          if (nextIndex >= 0) this.setActiveIcon(nextIndex);
          e.preventDefault();
          break;
        }
        case "ArrowRight":
        case "Right": {
          if (document.activeElement === this.searchInput.current) {
            break;
          }
          emitInteractionAnalyticsEvent(this.iconSelectTargetRef.current, {
            key: e.key,
          });
          const nextIndex = this.initialItemIndex + 1;
          if (nextIndex < this.filteredItems.length)
            this.setActiveIcon(nextIndex);
          e.preventDefault();
          break;
        }
        case "ArrowLeft":
        case "Left": {
          if (document.activeElement === this.searchInput.current) {
            break;
          }
          emitInteractionAnalyticsEvent(this.iconSelectTargetRef.current, {
            key: e.key,
          });
          const nextIndex = this.initialItemIndex - 1;
          if (nextIndex >= 0) this.setActiveIcon(nextIndex);
          e.preventDefault();
          break;
        }
        case " ":
        case "Enter": {
          if (
            this.searchInput.current === document.activeElement &&
            this.filteredItems.length !== 2
          )
            break;
          emitInteractionAnalyticsEvent(this.iconSelectTargetRef.current, {
            key: e.key,
          });
          this.handleIconChange(
            this.filteredItems[this.initialItemIndex],
            true,
          );
          // this.debouncedSetState({ isOpen: false });
          e.preventDefault();
          e.stopPropagation();
          break;
        }
        case "Escape": {
          emitInteractionAnalyticsEvent(this.iconSelectTargetRef.current, {
            key: e.key,
          });
          this.setState({
            isOpen: false,
            activeIcon: this.props.value ?? NONE,
          });
          e.stopPropagation();
        }
      }
    } else if (this.iconSelectTargetRef.current === document.activeElement) {
      switch (e.key) {
        case "ArrowUp":
        case "Up":
        case "ArrowDown":
        case "Down":
          this.debouncedSetState({ isOpen: true }, this.handleButtonClick);
          break;
        case "Tab":
          emitInteractionAnalyticsEvent(this.iconSelectTargetRef.current, {
            key: `${e.shiftKey ? "Shift+" : ""}${e.key}`,
          });
          break;
      }
    }
  };

  handleButtonClick = (e?: any) => {
    this.debouncedSetState({
      isOpen: true,
    });
  };
  // ItemListRenderer<IconType>
  renderMenu: any = ({ activeItem, filteredItems, renderItem }: any) => {
    const { searchValue } = this.state;
    if (
      activeItem === "(none)" ||
      activeItem === "NONE" ||
      activeItem === "" ||
      activeItem === undefined
    ) {
      this.filteredItems = filteredItems[0].direction;
    } else {
      this.filteredItems = _.get(
        filteredItems[0],
        `${activeItem.category}`,
        [],
      );
    }
    if (
      activeItem === "(none)" ||
      activeItem === "NONE" ||
      activeItem === "" ||
      activeItem === undefined
    ) {
      this.initialItemIndex = 0;
    } else {
      this.initialItemIndex = this.filteredItems.findIndex(
        (x) => x.iconName === activeItem.iconName,
      );
    }

    const onTopTabClick = (
      key: string,
      event: React.MouseEvent<Element> | React.KeyboardEvent<Element>,
    ) => {
      this.setState({ currentTopTab: key });
      this.debouncedSetState({ isOpen: true });
      event.stopPropagation();
      event.preventDefault();
    };

    const onLeftTabClick = (
      key: string,
      event: React.MouseEvent<Element> | React.KeyboardEvent<Element>,
    ) => {
      this.setState({ currentPlate: key });
      this.setState({ currentTopTab: "direction" });
      event.stopPropagation();
      event.preventDefault();
    };
    const filterItems = (category: string) => {
      const items = _.get(filteredItems[0], category, []);
      // const searchValue = this.searchInput.current?.value;

      if (_.isEmpty(searchValue)) {
        return items;
      }

      return items.filter((item: any) => item?.iconName.includes(searchValue));
    };

    const directionFiltedItem = filterItems("direction");
    const tipsFiltedItem = filterItems("tips");
    const editFiltedItem = filterItems("edit");
    const dataFiltedItem = filterItems("data");
    const logoFiltedItem = filterItems("logo");
    const websiteFiltedItem = filterItems("website");
    const appsmithFiltedItem = filterItems("appsmith");
    const tabTopItems = [
      {
        key: "direction",
        label: "方向性",
        children: (
          <VirtuosoGrid
            components={{
              List: StyledMenu,
            }}
            computeItemKey={(index) => directionFiltedItem[index]?.iconName}
            initialItemCount={18}
            itemContent={(index) => {
              return renderItem(directionFiltedItem[index], index);
            }}
            ref={this.virtuosoRef}
            style={{
              height: "165px",
            }}
            tabIndex={-1}
            totalCount={directionFiltedItem.length}
          />
        ),
      },
      {
        key: "tips",
        label: "提示建议类",
        children: (
          <VirtuosoGrid
            components={{
              List: StyledMenu,
            }}
            computeItemKey={(index) => tipsFiltedItem[index]?.iconName}
            initialItemCount={18}
            itemContent={(index) => {
              return renderItem(tipsFiltedItem[index], index);
            }}
            ref={this.virtuosoRef}
            style={{
              height: "165px",
            }}
            tabIndex={-1}
            totalCount={tipsFiltedItem.length}
          />
        ),
      },
      {
        key: "edit",
        label: "编辑类",
        children: (
          <VirtuosoGrid
            components={{
              List: StyledMenu,
            }}
            computeItemKey={(index) => editFiltedItem[index]?.iconName}
            initialItemCount={18}
            itemContent={(index) => {
              return renderItem(editFiltedItem[index], index);
            }}
            ref={this.virtuosoRef}
            style={{
              height: "165px",
            }}
            tabIndex={-1}
            totalCount={editFiltedItem.length}
          />
        ),
      },
      {
        key: "data",
        label: "数据类图标",
        children: (
          <VirtuosoGrid
            components={{
              List: StyledMenu,
            }}
            computeItemKey={(index) => dataFiltedItem[index]?.iconName}
            initialItemCount={18}
            itemContent={(index) => {
              return renderItem(dataFiltedItem[index], index);
            }}
            ref={this.virtuosoRef}
            style={{
              height: "165px",
            }}
            tabIndex={-1}
            totalCount={dataFiltedItem.length}
          />
        ),
      },
      {
        key: "logo",
        label: "品牌和标识",
        children: (
          <VirtuosoGrid
            components={{
              List: StyledMenu,
            }}
            computeItemKey={(index) => logoFiltedItem[index]?.iconName}
            initialItemCount={18}
            itemContent={(index) => {
              return renderItem(logoFiltedItem[index], index);
            }}
            ref={this.virtuosoRef}
            style={{
              height: "165px",
            }}
            tabIndex={-1}
            totalCount={logoFiltedItem.length}
          />
        ),
      },
      {
        key: "website",
        label: "网站通用",
        children: (
          <VirtuosoGrid
            components={{
              List: StyledMenu,
            }}
            computeItemKey={(index) => websiteFiltedItem[index]?.iconName}
            initialItemCount={18}
            itemContent={(index) => {
              return renderItem(websiteFiltedItem[index], index);
            }}
            ref={this.virtuosoRef}
            style={{
              height: "165px",
            }}
            tabIndex={-1}
            totalCount={websiteFiltedItem.length}
          />
        ),
      },
    ];

    const tabLeftItems = [
      {
        key: "antd",
        label: "Antd",
        children: (
          <ConfigProvider
            theme={{
              components: {
                Tabs: {},
              },
            }}
          >
            <>
              <StyleTopTabItem>
                <Tabs
                  className="custom-tabs-left"
                  activeKey={this.state.currentTopTab}
                  style={{ paddingLeft: "4px" }}
                  tabPosition={"top"}
                  items={tabTopItems}
                  onTabClick={onTopTabClick}
                />
              </StyleTopTabItem>
            </>
          </ConfigProvider>
        ),
      },
      {
        key: "appsmith",
        label: "Appsmith",
        children: (
          <VirtuosoGrid
            components={{
              List: StyledMenu,
            }}
            computeItemKey={(index) => appsmithFiltedItem[index]?.iconName}
            initialItemCount={16}
            itemContent={(index) =>
              renderItem(appsmithFiltedItem[index], index)
            }
            ref={this.virtuosoRef}
            style={{
              height: "165px",
            }}
            tabIndex={-1}
            totalCount={appsmithFiltedItem.length}
          />
        ),
      },
    ];

    return (
      <>
        <div className="flex items-center justify-between mb-[8px]">
          <span className="font-semibold text-[16px]">图标</span>
          <span
            className="cursor-pointer"
            onClick={() => this.debouncedSetState({ isOpen: false })}
          >
            <CancelIcon />
          </span>
        </div>
        <div className="mb-2">
          <Input
            allowClear
            ref={this.searchInput}
            placeholder="Filter..."
            prefix={<SearchOutlined />}
            onChange={(event) => {
              this.setState({ searchValue: event.target.value });
            }}
            onFocus={(e) => {
              e?.stopPropagation();
            }}
            onClick={(e) => {
              e?.stopPropagation();
            }}
          />
        </div>

        <ConfigProvider
          theme={{
            token: {
              paddingLG: 4,
            },
            components: {
              Tabs: {},
            },
          }}
        >
          <Tabs
            activeKey={this.state.currentPlate}
            tabPosition={"left"}
            items={tabLeftItems}
            onTabClick={onLeftTabClick}
          />
        </ConfigProvider>
      </>
    );
  };

  renderIconItem: ItemRenderer<IconObject> = (
    icon,
    { handleClick, modifiers },
  ) => {
    // if (!modifiers.matchesPredicate) {
    //   return null;
    // }
    if (!icon || !icon.iconName) {
      return null;
    }
    const { activeIcon } = this.state;
    return (
      <Tooltip content={icon?.iconName} mouseEnterDelay={0}>
        {icon?.type === "antd" ? (
          <StyledMenuItem
            active={icon?.iconName === activeIcon.iconName}
            icon={
              icon?.iconName === NONE ? undefined : (
                <AntdIcon
                  type={icon?.iconName}
                  style={{
                    marginLeft: "7px",
                    fontSize: "20px",
                    marginRight: "3px",
                  }}
                />
              )
            }
            key={icon?.iconName + icon?.category}
            onClick={handleClick}
            text={icon?.iconName === NONE ? NONE : undefined}
            textClassName={`${this.props.mode === "simple" || icon.iconName === NONE ? "bp3-icon-(none)" : ""}`}
          />
        ) : (
          <StyledMenuItem
            active={icon?.iconName === activeIcon.iconName}
            icon={icon.iconName === NONE ? undefined : icon.iconName}
            key={icon.iconName + icon?.category}
            onClick={handleClick}
            text={icon.iconName === NONE ? NONE : undefined}
            textClassName={`${this.props.mode === "simple" || icon.iconName === NONE ? "bp3-icon-(none)" : ""}`}
          />
        )}
      </Tooltip>
    );
  };

  filterIconName = (query: string, item: any) => {
    const currentTab = item[this.state.currentPlate];
    if (query === "") {
      return true;
    }
    // return item.toLowerCase().indexOf(query.toLowerCase()) >= 0;
    return true;
  };

  handleIconChange = (icon: IconType, isUpdatedViaKeyboard = false) => {
    if(_.isEmpty(icon)) return;
    const { onSelect } = this.props;
    this.setState({ activeIcon: icon });
    onSelect && onSelect(icon);
    // 这里回调 onSelect;
  };

  handleItemSelect = (icon: IconType) => {
    this.handleIconChange(icon, false);
  };
}

export default AntdIconSelector;
