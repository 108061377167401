import React, { useState, useCallback, useEffect } from "react";
import AddLibraryPopover from "./AddLibraryPopover";
import PaneHeader from "./PaneHeader";
import { useSelector, useDispatch } from "react-redux";
import { selectLibrariesForExplorer } from "ee/selectors/entitiesSelector";
import {
  appSnapshotCountSelector,
  appSnapshotsSelector,
  isAppSnapshotDslFetching,
  isAppSnapshotsFetching,
} from "ee/selectors/appSnapshotSelector";
import { getCurrentApplication, getCurrentPageName, getCurrentPageId } from "selectors/editorSelectors";
import styled from "styled-components";
import { Pagination, Modal, Empty, Spin } from "antd";
import { Icon, Tooltip } from "design-system";
import _get from "lodash/get";
import _size from "lodash/size";
import {
  fetchSnapshotDslAction,
  fetchSnapshotsAction,
  setSelectSnapshotId,
  recoverSnapshotAction,
} from "ee/actions/appSnapshotActions";
import type { AppSnapshot, AppSnapshotList } from "constants/AppConstants";
import { OperationLabel, OperationType } from "constants/AppConstants"
import moment from 'moment';
import { fetchPage } from "actions/pageActions";

const Container = styled.div`
  width: 300px;
  position: relative;
  height: 100%;
  min-height: 100px;
`;
const ListContainer = styled.div`
  height: calc(100% - 100px);
  overflow-y: auto;
`

const EmptyContainer = styled(Empty)`
  margin-top: 20px;
`;

const StyledWrapper = styled.div<{ isSelected: boolean }>`
  background-color: ${({ isSelected }) =>
    isSelected ? "var(--ads-v2-color-bg-muted)" : "transparent"};

  &:hover {
    background-color: ${({ isSelected }) =>
    isSelected
      ? "var(--ads-v2-color-bg-muted)"
      : "var(--ads-v2-color-bg-subtle)"};
  }
  padding: 10px 8px;
`;

const Title = styled.p`
  font-size: 14px;
  line-height: 1.2rem;
  font-weight: var(--ads-v2-font-weight-bold);
  color: var(--ads-v2-color-fg-emphasis);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 212px;
`;

const SubTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  height: 16px;
  justify-content: space-between;
  margin-top: 8px;
  .user {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 104px;
    font-size: 13px;
    color: rgb(139, 143, 163);
    line-height: 16px;
  }
  .date {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 13px;
    color: rgb(184, 185, 191);
    line-height: 16px;
  }
`;
const BottomWrap = styled.div`
  padding: 12px 16px;
  height: 56px;
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 0px;
  width: 100%;
  background: #ffffff;
  z-index: 1;
`;
const Tag = styled.div`
  height: 100%;
  position: absolute;
  bottom: 0px;
  top: 0px;
  left: 0px;
  width: 6px;
  border: 1px solid #ffffff;
  border-radius: 2px;
`;
const PAGE_SIZE = 9;
const CURRENT_ITEM_KEY = "current_key";
// const TIME_FORMAT = trans("history.timeFormat");

const HistorySidePane = () => {
  const dispatch = useDispatch();
  const application = useSelector(getCurrentApplication);
  const appSnapshots = useSelector(appSnapshotsSelector);
  const totalCount = useSelector(appSnapshotCountSelector);
  const snapshotsFetching = useSelector(isAppSnapshotsFetching);
  const [current, setCurrent] = useState(1);
  const currentPageName = useSelector(getCurrentPageName);
  const currentPageId = useSelector(getCurrentPageId);
  const [selectedItemKey, setSelectedItemKey] = useState(CURRENT_ITEM_KEY);
  const showPropsConfirm = (item: AppSnapshot) => {
    Modal.confirm({
      title: '确定回退到这个版本吗?',
      content: <div>
        <SubTitle>
          <div className="date" title={item.username}>
            创建人:{item.username}
          </div>
          <div className="date" title={moment(item.createTime).format('YYYY/MM/DD HH:mm:ss')}>
            创建时间:{moment(item.createTime).format('YYYY/MM/DD HH:mm:ss')}
          </div>
        </SubTitle>
        <div className="my-2">
          {
            _get(item, "context.operations", [])
              ?.map((it) => `${OperationLabel[it?.operation]} ${it?.compName}`)
              .join("，")
          }
        </div>
      </div>,
      okText: '确定',
      cancelText: '取消',
      onOk() {
        onRecoverTheVersion(item.snapshotId, item.createTime)
      },
      onCancel() {
      },
    });
  };
  const fetchSnapshotList = (page: number, cPageId: string, onSuccess?: (snapshots: AppSnapshotList) => void) => {
    dispatch(setSelectSnapshotId(""));
    application &&
      dispatch(
        fetchSnapshotsAction({
          applicationId: application.id,
          pageId: cPageId,
          page: page,
          size: PAGE_SIZE,
          onSuccess,
        })
      );
  };

  useEffect(() => {
    if (!application) {
      return;
    }
    onPageChange(1);
  }, [currentPageId]);

  useEffect(() => {
    return () => {
      dispatch(fetchPage(currentPageId, false));
    }
  }, [])

  // click current snapshot to preview
  const onSnapshotItemClick = useCallback(
    (snapshotId: string) => {
      if (selectedItemKey === snapshotId) {
        return;
      }
      setSelectedItemKey(snapshotId);
      dispatch(setSelectSnapshotId(snapshotId === CURRENT_ITEM_KEY ? "" : snapshotId));
      if (snapshotId === CURRENT_ITEM_KEY) {
        // setAppInfo(currentAppInfo);
        return;
      }
      if (!application) {
        return;
      }
      // const pageName = currentPageName || "";
      dispatch(
        fetchSnapshotDslAction(snapshotId, (pageDetail) => { })
      );
    },
    [application, dispatch, selectedItemKey]
  );

  const onPageChange = useCallback(
    (page) => {
      fetchSnapshotList(page, currentPageId, (res: any) => { });
      setCurrent(page)
    },
    [current, currentPageId],
  );

  // recover the snapshot of this version
  const onRecoverTheVersion = useCallback((sId: string, sTime: any) => {
    if (application) {
      dispatch(
        recoverSnapshotAction(
          sId,
          sTime,
          () => {
            // console.log("回退成功")
          }
        ),
      );
    }
  }, [application]);

  return (
    <Container>
      <PaneHeader
        rightIcon={<Icon className="mr-1" name={"history"} size="md" />}
        title={`历史记录（${currentPageName})`}
      />
      {
        !_size(appSnapshots) && !snapshotsFetching && (<EmptyContainer description="暂无历史" />)
      }
      <ListContainer >
        <div className="listContent">
          <Spin spinning={snapshotsFetching} >
            {appSnapshots.map((item: AppSnapshot) => {
              const isPublish = item?.context.operations.find(it => it?.operation === OperationType.publish);
              const isRecover = item?.context.operations.find(it => it?.operation === OperationType.recover);
              const tagBgClass = isPublish ? "bg-primary-400" : isRecover ? "bg-blue-300" : ""
              return (
                <StyledWrapper
                  className="flex flex-row items-center cursor-pointer relative"
                  id={item?.snapshotId}
                  isSelected={item?.snapshotId === selectedItemKey}
                  onClick={() => onSnapshotItemClick(item?.snapshotId)}
                  key={item?.snapshotId}
                >
                  {tagBgClass && <Tag className={tagBgClass} />}
                  <div className="flex flex-col w-full" style={{ zIndex: 1 }}>
                    <div className="flex flex-row justify-between">
                      <Tooltip content={_get(item, "context.operations", [])
                        ?.map((it) => `${OperationLabel[it?.operation]} ${it?.compName}`)
                        .join("，")}>
                        <Title>
                          {_get(item, "context.operations", [])
                            ?.map((it) => `${OperationLabel[it?.operation]} ${it?.compName}`)
                            .join("，")}
                        </Title>
                      </Tooltip>
                      {
                        item?.snapshotId === selectedItemKey ? (
                          <Tooltip content="恢复到这个版本">
                            <Icon
                              className="text-gray-400 hover:text-primary-500 rounded-md"
                              name={"arrow-go-back"}
                              size="md"
                              onClick={(e) => {
                                e.stopPropagation();
                                showPropsConfirm(item)
                              }}
                            />
                          </Tooltip>
                        ) : null
                      }
                    </div>

                    <SubTitle>
                      <span className="user" title={item.username}>
                        {item.username}
                      </span>
                      <span className="date" title={moment(item.createTime).format('YYYY/MM/DD HH:mm:ss')}>
                        {moment(item.createTime).format('YYYY/MM/DD HH:mm:ss')}
                      </span>
                    </SubTitle>
                  </div>
                </StyledWrapper>
              );
            })}
          </Spin>
        </div>
      </ListContainer>
      <BottomWrap>
        <Pagination
          showLessItems
          size="small"
          showSizeChanger={false}
          showQuickJumper={false}
          current={current}
          total={totalCount}
          onChange={onPageChange}
        />
      </BottomWrap>
    </Container>
  );
};

export default HistorySidePane;
