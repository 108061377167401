import styled, { createGlobalStyle } from "styled-components";
import { Alignment, Button, Classes, MenuItem } from "@blueprintjs/core";
import type { GridListProps, VirtuosoGridHandle } from "react-virtuoso";
import type { IconName } from "@blueprintjs/icons";
import { IconNames } from "@blueprintjs/icons";
import { Select } from "@blueprintjs/select";

export const IconSelectContainerStyles = createGlobalStyle<{
    targetWidth: number | undefined;
    id: string;
  }>`
    ${({ id, targetWidth }) => `
      .icon-select-popover-${id} {
        width: 500px;
        right: 2px;
        padding: 10px;
        background: white;
        .bp3-input-group {
          margin: 5px !important;
        }
      }
      
    `}
  `;
  
export const StyledButton = styled(Button)`
    box-shadow: none !important;
    border: 1px solid var(--ads-v2-color-border);
    border-radius: var(--ads-v2-border-radius);
    height: 36px;
    background-color: #ffffff !important;
    > span.bp3-icon-caret-down {
      color: rgb(169, 167, 167);
    }
    
    .bp3-button::before, .bp3-button > * {
      margin-right: 0px;
    }
  
    &:hover {
      border: 1px solid var(--ads-v2-color-border-emphasis);
    }
  
    &:focus {
      outline: var(--ads-v2-border-width-outline) solid
        var(--ads-v2-color-outline);
      border: 1px solid var(--ads-v2-color-border-emphasis);
    }
    
  `;
  
export const StyledMenu = styled.ul<GridListProps>`
    display: grid;
    grid-template-columns: repeat(6, 3fr);
    grid-auto-rows: minmax(40px, auto);
    gap: 20px;
    max-height: 170px !important;
    padding-left: 5px !important;
    padding-right: 5px !important;
    & li {
      list-style: none;
    }
  `;
  
export const StyledMenuItem = styled(MenuItem)`
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 13px 5px;
    height: 100%;
    &:active,
    &.bp3-active {
      background-color: var(--ads-v2-color-bg-muted) !important;
      border-radius: var(--ads-v2-border-radius) !important;
    }
  
    &&&&.bp3-menu-item:hover {
      background-color: var(--ads-v2-color-bg-subtle) !important;
      border-radius: var(--ads-v2-border-radius) !important;
    }
  
    &&&&.bp3-menu-item {
      color: var(--ads-v2-color-fg) !important;
    }
  
    &:hover {
      background-color: var(--ads-v2-color-bg-subtle) !important;
      border-radius: var(--ads-v2-border-radius) !important;
    }
  
    > a.bp3-menu-item {
      color: var(--ads-v2-color-fg) !important;
    }
  
    > a {
      color: var(--ads-v2-color-fg) !important;
    }
  
    > div {
      width: 100%;
      text-align: center;
      color: var(--ads-v2-color-fg) !important;
    }
  `;
  
  
export const StyleTopTabItem = styled.div`
    .ant-tabs {
      .ant-tabs-left
        > .ant-tabs-content-holder
        > .ant-tabs-content
        > .ant-tabs-tabpane {
        padding-left: 4px !important;
      }
    }
  `;

  export interface IconSelectControlProps {
    propertyValue?: any;
    defaultIconName?: IconName;
    hideNoneIcon?: boolean;
  }
  
  export interface IconSelectControlState {
    activeIcon: IconType;
    isOpen: boolean;
    currentPlate: string;
    currentTopTab: string;
  }
  
  export const NONE = "NONE";
  // type IconType = IconName | typeof NONE;
  export type IconType = any;
  export const ICON_NAMES = Object.keys(IconNames).map<IconType>(
    (name: string) => IconNames[name as keyof typeof IconNames],
  );
  export const icons = new Set(ICON_NAMES);
  
  export const TypedSelect = Select.ofType<IconType>();
  
  export interface IconObject {
    iconName: string;
    type?: string;
    category?: string;
  }
  