import React from "react";
import QuickGitActions from "pages/Editor/gitSync/QuickGitActions";
import { DebuggerTrigger } from "components/editorComponents/Debugger";
import HelpButton from "pages/Editor/HelpButton";
import ManualUpgrades from "./ManualUpgrades";
import { getAppsmithConfigs } from "ee/configs";
const { inCloudOS } = getAppsmithConfigs();
import { Button } from "design-system";
import SwitchEnvironment from "ee/components/SwitchEnvironment";
import { Container, Wrapper } from "./components";
import { useSelector } from "react-redux";
import { getCurrentApplicationId } from "selectors/editorSelectors";
import { useDispatch } from "react-redux";
import { softRefreshActions } from "actions/pluginActionActions";
import { START_SWITCH_ENVIRONMENT } from "ee/constants/messages";
import { ApplicationPayload } from "ee/constants/ReduxActionConstants";
import { getCurrentApplication } from "ee/selectors/applicationSelectors";

export default function BottomBar({ viewMode }: { viewMode: boolean }) {
  const appId = useSelector(getCurrentApplicationId) || "";
  const dispatch = useDispatch();
  const currentApplication: ApplicationPayload = useSelector(
    getCurrentApplication,
  )!;
  const onChangeEnv = () => {
    dispatch(softRefreshActions());
  };

  const { inCloudOS } = getAppsmithConfigs();
  if (inCloudOS) {
    return (
      <Container>
        <Wrapper>
          {/* <SwitchEnvironment viewMode={viewMode} />
          {!viewMode && <QuickGitActions />} */}
          <span />
        </Wrapper>
        {!viewMode && (
          <Wrapper>
            {/* <ManualUpgrades showTooltip>
              <Button
                className="t--upgrade"
                isIconButton
                kind="tertiary"
                size="md"
                startIcon="upgrade"
              />
            </ManualUpgrades>
            <DebuggerTrigger />
            <HelpButton /> */}
            <DebuggerTrigger />
          </Wrapper>
        )}
      </Container>
    );
  }
  return (
    <Container>
      {/* {inCloudOS ? <span /> : <QuickGitActions />} */}
      <Wrapper>
        {/* {!viewMode && (
          <SwitchEnvironment
            editorId={appId}
            onChangeEnv={onChangeEnv}
            startSwitchEnvMessage={START_SWITCH_ENVIRONMENT}
            viewMode={viewMode}
          />
        )} */}
        {!viewMode && !inCloudOS && currentApplication?.type !== "module" && <QuickGitActions />}
      </Wrapper>
      {!viewMode && (
        <Wrapper>
          <ManualUpgrades showTooltip>
            <Button
              className="t--upgrade"
              isIconButton
              kind="tertiary"
              size="md"
              startIcon="upgrade"
            />
          </ManualUpgrades>
          <DebuggerTrigger />
          <HelpButton />
        </Wrapper>
      )}
    </Container>
  );
}
