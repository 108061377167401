import React from "react";
import type { TreeRenderProps } from "react-complex-tree";
import { Classes, Colors } from "@blueprintjs/core";
// import { Button, Typography, Collapse } from "antd"
import { MENU_KEY, PAGE_KEY } from "./data";
import {
  MinusCircleOutlined,
  PlusCircleOutlined,
  CheckOutlined,
} from "@ant-design/icons";

// import styles from "./index.less";

const cx = (...classNames: Array<string | undefined | false>) =>
  classNames.filter((cn) => !!cn).join(" ");

export const renderers: TreeRenderProps = {
  renderTreeContainer: (props) => (
    <div className={cx(Classes.TREE)}>
      <ul
        className={cx(Classes.TREE_ROOT, Classes.TREE_NODE_LIST)}
        {...props.containerProps}
      >
        {props.children}
      </ul>
    </div>
  ),

  renderItemsContainer: (props) => {
    return (
      <ul
        className={cx(
          Classes.TREE_NODE_LIST,
          props.info.treeId === `${PAGE_KEY}-tree` && "page_list",
        )}
        {...props.containerProps}
      >
        {props.children}
      </ul>
    );
  },

  renderItemArrow: (props) => {
    if (props.info.rootItem === PAGE_KEY) return null;
    if (props.context.isExpanded) {
      return (
        <MinusCircleOutlined
          className={cx(
            Classes.TREE_NODE_CARET,
            props.context.isExpanded
              ? Classes.TREE_NODE_CARET_OPEN
              : Classes.TREE_NODE_CARET_CLOSED,
          )}
          style={{ background: "#fff", color: "#666666" }}
          {...(props.context.arrowProps as any)}
        />
      );
    }
    return (
      <PlusCircleOutlined
        className={cx(
          Classes.TREE_NODE_CARET,
          props.context.isExpanded
            ? Classes.TREE_NODE_CARET_OPEN
            : Classes.TREE_NODE_CARET_CLOSED,
        )}
        style={{ background: "#fff", color: "#666666" }}
        {...(props.context.arrowProps as any)}
      />
    );
  },

  renderDragBetweenLine: ({ draggingPosition, lineProps }) => {
    if (draggingPosition.treeId === `${PAGE_KEY}-tree`) {
      return <></>;
    }
    return (
      <div
        {...lineProps}
        style={{
          position: "absolute",
          right: "0",
          top:
            draggingPosition.targetType === "between-items" &&
            draggingPosition.linePosition === "top"
              ? "0px"
              : draggingPosition.targetType === "between-items" &&
                  draggingPosition.linePosition === "bottom"
                ? "-4px"
                : "-2px",
          left: `${draggingPosition.depth * 40}px`,
          width: "32rem",
          height: "2px",
          backgroundColor: "#13C2C2",
        }}
      />
    );
  },

  renderSearchInput: (props) => {
    return null;
  },

  renderDepthOffset: 1,
};
